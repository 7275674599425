import { graphql } from "gatsby";
import React from "react";
import PageWithHeader from "../components/pageWithHeader";
import SimpleCard from "../components/simpleCard";
import { DataComponent } from "../helpers/dataHelpers";
import style from './news.module.scss';

export type NewsProps = {

};

const News: DataComponent<GatsbyTypes.NewsQuery, NewsProps> = ({data}) => {
  const list = data.allContentfulArticle.edges.map(l => l.node);

  return (<PageWithHeader
    title="Centeva in the News">
    <article>
      <h2>Latest News</h2>
      <div className={style.list}>
        {list.map(({title, slug, createdAt, description}) => (
        <div className={style.card}>
          <SimpleCard
            title={title} 
            description={description?.description} 
            link={`article/${slug}`}
            showShare
            createdAt={createdAt}/>
        </div>))}
      </div>
    </article>
  </PageWithHeader>)
};

export default News;

export const data = graphql`
query News {
  allContentfulArticle(filter: {category: {category: {eq: "News"}}}) {
    edges {
      node {
        description {
          description
        }
        slug
        title
        createdAt(formatString: "MMMM D, YYYY")
      }
    }
  }
}
`;